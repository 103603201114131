<template>
  <div class="pa-4" style="background-color:#dcdcdc">
    <v-row>
      <v-col :cols="12" md="6">
        <v-card>
          <v-toolbar dense>
            <v-toolbar-title>Pending Changes</v-toolbar-title>
          </v-toolbar>
          <change v-for="{ _id, action, status, course, approvals } in changes" :key="'alert-' + _id" :action="action" :status="status" :course="course" :approvals="approvals" @cancel="cancelChange(_id)"></change>
        </v-card>
        <v-expansion-panels v-if="completed.length + cancelled.length > 0" class="mt-4">
          <v-expansion-panel v-if="completed.length > 0">
            <v-expansion-panel-header>Completed Changes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <change v-for="{ _id, action, cancelled, course } in completed" :key="'alert-' + _id" :action="action" :status="cancelled ? 'cancelled' : 'completed'" :course="course"></change>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="cancelled.length > 0">
            <v-expansion-panel-header>Cancelled Changes</v-expansion-panel-header>
            <v-expansion-panel-content>
              <change v-for="{ _id, action, cancelled, course } in cancelled" :key="'alert-' + _id" :action="action" :status="cancelled ? 'cancelled' : 'completed'" :course="course"></change>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <v-col :cols="12" md="6">
        <v-card style="background-color:#eeeeee">
          <v-toolbar dense>
            <v-toolbar-title>Timeline</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="addCommentDialog" width="400">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>fal fa-comment-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Add Comment</v-card-title>
                <v-card-text>
                  <p>Add a comment/message for the instructors, advisors, and/or the Records office.</p>
                  <v-textarea v-model="comment" label="Comment/Message" outlined hide-details></v-textarea>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="addCommentDialog = false">Cancel</v-btn>
                  <v-btn :disabled="comment === ''" color="success" text @click="addComment">Add Comment</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item v-for="message in timeline" :key="'message-' + message._id" :color="message.color || 'info'" :class="!(message.icon) && message.pidm !== user.pidm ? 'reverse-icon' : ''">
                <template v-slot:icon>
                  <v-icon color="white" small>{{ message.icon || 'fal fa-comment'}}</v-icon>
                </template>
                <v-card @click="message.expanded = !message.expanded" style="cursor:pointer">
                  <v-card-text :style="message.expanded ? 'padding-bottom:10px' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'" v-html="message.text"></v-card-text>
                  <v-card-text style="font-size:.8em;opacity:.7;padding-top:0">{{ message.name }} -- {{ stringFormatDate(message.date) }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { ref, watch, computed } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  props: {
    term: {
      type: String,
      required: true
    },
    scheduleUpdatedAt: {
      type: String,
      required: true
    }
  },
  components: {
    change: () => import('./timeline/change')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const changeId = ref('')
    const changes = ref([])
    const completed = ref([])
    const cancelled = ref([])
    const timeline = ref([])
    async function load () {
      const aggregate = [
        { $match: { term: props.term, pidm: user.value.pidm } },
        { $unwind: '$changes' },
        { $lookup: {
          from: 'Calendar-Classes',
          localField: 'changes.crn',
          foreignField: 'crn',
          as: 'course',
          let: { term: '$term' },
          pipeline: [
            { $match: { $expr: { $eq: ['$term', '$$term'] } } },
            { $project: {
              title: 1,
              instructor: { $first: '$instructors' },
              meetingBase: 1
            } },
            { $project: {
              title: 1,
              instructor: '$instructor.name',
              meets: {
                $reduce: {
                  input: '$meetingBase',
                  initialValue: '',
                  in: {
                    $concat: [
                      '$$value',
                      { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                      { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                      { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                      '$$this.endTime',
                      { $cond: [
                        { $eq: ['$$this.room', null] },
                        '',
                        { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                      ] }
                    ]
                  }
                }
              }
            } }
          ]
        } },
        { $project: {
          timeline: 1,
          change: '$changes',
          completed: { $size: '$completed' },
          course: { $first: '$course' } }
        }
      ]
      const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
      if (data.length === 0) {
        changes.value = []
        timeline.value = []
      } else {
        changeId.value = data[0]._id
        changes.value = data.map(({ change, course }) => { return { ...change, course } })
        if ('completed' in data[0] && data[0].completed > 0) {
          const aggregate = [
            { $match: { term: props.term, pidm: user.value.pidm } },
            { $unwind: '$completed' },
            { $lookup: {
              from: 'Calendar-Classes',
              localField: 'completed.crn',
              foreignField: 'crn',
              as: 'course',
              let: { term: '$term' },
              pipeline: [
                { $match: { $expr: { $eq: ['$term', '$$term'] } } },
                { $project: {
                  title: 1,
                  instructor: { $first: '$instructors' },
                  meetingBase: 1
                } },
                { $project: {
                  title: 1,
                  instructor: '$instructor.name',
                  meets: {
                    $reduce: {
                      input: '$meetingBase',
                      initialValue: '',
                      in: {
                        $concat: [
                          '$$value',
                          { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                          { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                          { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                          '$$this.endTime',
                          { $cond: [
                            { $eq: ['$$this.room', null] },
                            '',
                            { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                          ] }
                        ]
                      }
                    }
                  }
                } }
              ]
            } },
            { $project: {
              complete: '$completed',
              course: { $first: '$course' } }
            }
          ]
          const { data } = await root.$feathers.service('student/schedule-change').find({ query: { aggregate } })
          data.forEach(({ complete, course }) => {
            if (complete.cancelled) {
              cancelled.value.push({ ...complete, course })
            } else {
              completed.value.push({ ...complete, course })
            }
          })
        }
        timeline.value = data[0].timeline.reverse().map((row) => { return { ...row, expanded: false } })
      }
    }
    watch(() => props.term, () => {
      load()
    })

    const addCommentDialog = ref(false)
    const comment = ref('')
    async function addComment () {
      timeline.value.splice(0, 0, { pidm: user.value.pidm, date: new Date(), icon: 'fal fa-comment', color: 'info', text: comment.value })
      addCommentDialog.value = false
      comment.value = ''
    }

    async function cancelChange (itemId) {
      const temp = changes.value.find(({ _id }) => itemId === _id)
      const comment = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Cancelled request to ' + temp.action + ' course: ' + temp.course.title,
        icon: 'fal fa-times-circle',
        color: 'error',
        visibleToStudent: true
      }
      const patch = { $pull: { changes: { _id: itemId } }, $push: { completed: { ...temp, cancelled: true }, timeline: comment } }
      try {
        // Email any approvers who had previously been emailed but who have not yet approved to let them know it has been cancelled
        const pidms = changes.value.find(({ _id }) => _id === itemId).approvals.filter(({ date, emailSent }) => emailSent === true && typeof (date) === 'undefined').map(({ pidm }) => pidm)
        if (pidms.length > 0) {
          const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: pidms }, $select: ['email'] } })
          const to = data.map(({ email }) => email)
          if (to.length > 0) {
            await root.$feathers.service('system/email').create({ to, subject: 'Scheduled Change Cancelled', from: 'report-email@covenant.edu', body: 'The schedule change request for ' + user.value.name + ' has been cancelled so you no longer need to approve it.' })
          }
        }
        await root.$feathers.service('student/schedule-change').patch(changeId.value, patch)
        load()
        root.$store.dispatch('main/snackbar', { color: 'success', text: 'Requested change was cancelled successfully' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error cancelling change: ' + e })
      }
    }

    return {
      user,
      changes,
      completed,
      cancelled,
      timeline,
      load,
      addCommentDialog,
      comment,
      addComment,
      cancelChange,
      stringFormatDate
    }
  }
}
</script>
